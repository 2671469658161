<template>
  <div class="main">
    <div class="main-item header p-5 bg-white">
      <div class="header-1 flex justify-between">
        <div class="header-1-1">使用统计</div>
      </div>
    </div>
    <!-- <div class="main-body mt-22px flex flex-wrap">
          <item class="item" v-for="item in resData " :key='item.abilityId' :ability-data='item'></item>
      </div> -->
    <div class="main-body mt-5">
      <div class="main-top p-6">
        <div class="main-top-1">已开通服务</div>
        <div
          v-loading="this.loading.listAbilityUsagePost"
          class="flex justify-center items-center"
        >
          <div
            v-if="
              !(
                Array.isArray(listAbilityUsageResData) &&
                listAbilityUsageResData.length > 0
              )
            "
            class="h-32 flex flex-col justify-center items-center"
          >
            <div>
              <img
                src="@/assets/icon/none-data.png"
                alt=""
                width="60px"
                height="40px"
              />
            </div>
            <div class="none-data">暂无数据</div>
          </div>
          <!-- <div v-else class="grid flex-wrap justify-center "> -->
          <div v-else class="main-top-2 flex flex-wrap justify-center">
            <div class="ability-pie-list flex flex-wrap justify-between">
              <div
                class="ability-pie-item flex items-stretch justify-center my-6"
                v-for="(item, index) in listAbilityUsageResData"
                :key="item.abilityId"
              >
                <!-- v-for="(item, index) in listAbilityUsageResData" -->

                <!-- <div class="pie-item-line w-px"></div> -->
                <div
                  v-if="(index + 1) % 4 !== 1"
                  class="pie-item-line w-px"
                ></div>
                <div class="pie-item-main w-52 flex flex-col items-center">
                  <div class="pie-item-title">{{ item.abilityName }}</div>
                  <div class="pie-item-body mt-4">
                    <div :id="`pie-container-${index}`"></div>
                  </div>
                  <div class="pie-item-foot mt-4 flex justify-center">
                    <div class="pie-item-foot-1">
                      <div class="pie-item-foot-value pie-item-foot-value--1">
                        {{ item.restCount }}
                      </div>
                      <div class="pie-item-foot-key">剩余次数</div>
                    </div>
                    <div class="pie-item-foot-2 ml-5">
                      <div class="pie-item-foot-value pie-item-foot-value--2">
                        {{ item.todayUsage }}
                      </div>
                      <div class="pie-item-foot-key">今日使用</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-center mt-5 p-6 pb-0">
        <div class="main-top-1">接口调用统计</div>
        <div class="main-center-2 mt-6 flex flex-wrap items-center">
          <div class="flex items-center mb-6">
            <div>
              <span class="select-key">应用名称</span>
              <!-- <span class="select-value">应用名称</span> -->
              <el-select
                v-model="isvSelectObj.value"
                filterable
                class="select-value"
              >
                <!-- <el-option :key="-1" label="全部" :value="null"> </el-option> -->
                <el-option
                  v-for="item in isvSelectObj.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div class="mx-10 flex items-center">
              <div class="select-key">能力项</div>
              <!-- <span class="select-value">应用名称</span> -->

              <el-select
                v-model="abilitySelectObj.value"
                filterable
                class="select-value"
              >
                <el-option :key="0" label="全部" :value="0"> </el-option>
                <el-option
                  v-for="item in abilitySelectObj.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="time-select-layout flex flex-nowrap mb-6">
            <div>
              <span class="select-key">统计周期</span>

              <el-date-picker
                v-model="dateSelectValueList"
                type="daterange"
                size="normal"
                range-separator="-"
                start-placeholder=""
                end-placeholder=""
                class="time-select-value ml-5"
                :clearable="false"
              >
              </el-date-picker>
            </div>
            <div class="ml-14">
              <el-button
class="l-btn-default" @click="clickBtnQuery"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div v-loading="this.loading.pageStatisticPost">
          <div
            v-if="
              !(
                Array.isArray(pageStatisticResData) &&
                pageStatisticResData.length > 0
              )
            "
            class="h-32 flex flex-col justify-center items-center"
          >
            <div>
              <img
                src="@/assets/icon/none-data.png"
                alt=""
                width="60px"
                height="40px"
              />
            </div>
            <div class="none-data">暂无数据</div>
          </div>
          <div v-else class="mt-6">
            <el-table :data="pageStatisticResData" class="l-table">
              <el-table-column
                v-for="col in columns"
                :prop="col.id"
                :key="col.id"
                :label="col.label"
                :width="col.width"
                align="center"
              >
              </el-table-column>
            </el-table>
            <div class="mt-8 flex justify-end">
              <el-pagination
                :current-page.sync="pageObj.pageNo"
                :page-size="pageObj.pageSize"
                layout="total, prev,  next"
                :total="pageObj.total"
                prev-text="上一页"
                next-text="下一页"
                @current-change="changePage"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Item from '@/components/statistics/Item.vue'
import dayjs from 'dayjs'
import _ from  'lodash'

import { Pie  } from '@antv/g2plot'

export default {
  // el: '', // string | Element
  name: "", // string
  // parent: '', // Vue instance
  // functional: false, // boolean
  // delimiters: ['{{', '}}'], // Array<string>
  // comments: false,
  components: {
    // Item
  }, // Object
  // directives: {}, // Object
  // filters: {}, // Object
  // extends: '', // Object | Function
  // mixins: [], // Array<Object>
  // inheritAttrs: true, // boolean
  // model: '', // { prop?: string, event?: string }
  props: {}, // Array<string> | Object
  data() {
    return {
      queryParams:{
          abilityId:null,
          isvId:null,
          beginTime:null,
          endTime:null,
          pageNo:null,
          pageSize:null,
        },
        listAbilityUsageResData:[],
      pageStatisticResData: [],
      pageObj:{
        pageNo:1,
        pageSize:10,
        pages:null,
        total:null,
      },
      dateSelectValueList:[
        dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
        ],
      isvSelectObj: {
        value: 0,
        options: []
      },
      abilitySelectObj: {
        value: 0,
        options: []
      },
      columns: [
        {
          id: "date",
          label: "日期",
          width: ""
        },
        {
          id: "appName",
          label: "应用名称",
          width: ""
        },
        {
          id: "abilityName",
          label: "能力项",
          width: ""
        },
        {
          id: "count",
          label: "调用次数",
          width: ""
        }
      ],
      loading:{
        listAbilityUsagePost:false,
        pageStatisticPost:false,
      }
    };
  },
  computed: {}, // { [key: string]: Function | { get: Function, set: Function } }
  // watch: {}, // { [key: string]: string | Function | Object | Array }
  // beforeCreate() { },
  // created() { },
  // beforeMount() { },
  mounted() {
    this.listAbilityUsagePost()
        this.enumIsvGet()
    this.enumAbilityGet()
          this.queryParams={
          beginTime:this.dateSelectValueList[0],
          endTime:this.dateSelectValueList[1],
          pageNo:this.pageObj.pageNo,
          pageSize:this.pageObj.pageSize,
          abilityId:this.abilitySelectObj.value,
          isvId:this.isvSelectObj.value,

        }
    this.pageStatisticPost()

  },
  // beforeUpdate() { },
  // updated() { },
  // activated() { },
  // deactivated() { },
  // beforeDestroy() { },
  // destroyed() { },
  methods: {
    test() {},
    clickBtnQuery(){
      this.queryParams={
          abilityId:this.abilitySelectObj.value,
          isvId:this.isvSelectObj.value,
          beginTime:this.dateSelectValueList[0],
          endTime:this.dateSelectValueList[1],
          pageNo:1,
          pageSize:this.pageObj.pageSize,
        }
        this.pageStatisticPost()
    },
    changePage(pageNo){
      this.queryParams.pageNo = pageNo
      this.pageStatisticPost()
    },
    listAbilityUsagePost() {
      this.loading.listAbilityUsagePost = true
      this.$http(
        "get",
        "/op/website/statistic/listAbilityUsage",
        {},
        {
          // mock: 22
        }
      ).then(res => {
        this.listAbilityUsageResData = res
        // this.pageObj.pageNo = res.pageNo
        // this.pageObj.total = res.total
      }).catch(
        ()=>{
        this.listAbilityUsageResData = []
        }
      ).finally(()=>{
              this.loading.listAbilityUsagePost = false
              this.$nextTick(()=>{
                this.drawPie()
              })
      })
    },



    drawPie(){
      // return
this.listAbilityUsageResData.forEach((element,index) => {

let totalCount =  element.restCount +element.todayUsage

let lessCountObjName = ''
let lessCount = 0
let count1 = element.restCount
let count2 = element.todayUsage
if(element.restCount>element.todayUsage){
lessCountObjName = 'todayUsage'
lessCount =element.todayUsage
}else{
lessCountObjName = 'restCount'
lessCount = element.restCount
}

if(lessCount/totalCount < 0.1){
  lessCount = totalCount * 0.1
if( lessCountObjName === 'todayUsage' ){
count2 = lessCount
count1 =  totalCount - lessCount
}else{
count1 = lessCount
count2 =  totalCount - lessCount
}
}

if(count1 === 0 && count2 ===0 ){
  count1 =1
  count2 =1
  totalCount=2
}


const data = [
  { type: '剩余次数', value: count1 },
  { type: '今日使用', value: count2 },
]
const otherRatio = count2 / totalCount; // Other 的占比
const otherOffsetAngle = otherRatio * Math.PI; // other 占的角度的一半
const piePlot = new Pie(`pie-container-${index}`, {
  data,
  legend: false, // 关闭图例
  angleField: 'value',
  colorField: 'type',
  width:100,
  height:100,
  autoFit:false,
  radius:0.9,
    innerRadius: 0.6,
    statistic:{
      title:false,
      content:false,
      customHtml :'',
      formatter:()=>{},
    },
  autoFit:false,
   pieStyle: {
    lineWidth: 1,
  },
  color: ['#0078ff', '#0ad4cd'],
    label: {
    type: 'inner',
    // offset: '-50%',
    // autoRotate: false,
    // style: { textAlign: 'center' },
    formatter: ()=>{},
  },
  tooltip:{
      // fields: [],
    showContent:false,

      showTitle:false,
formatter:()=>{}
  },
  // radius:0.5,
    startAngle: 0 + otherOffsetAngle ,
  endAngle: Math.PI * 2 + otherOffsetAngle  ,
});
piePlot.render()
      });
    },
    pageStatisticPost() {
      this.loading.pageStatisticPost = true
      this.$http(
        "post",
        "/op/website/statistic/pageStatistic",
        this.queryParams,
        {
          // mock: 22
        }
      ).then(res => {
        this.pageStatisticResData = res.records
        this.pageObj.pageNo = res.pageNo
        this.pageObj.total = res.total
      }).catch(
        ()=>{
        this.pageStatisticResData = []
        }
      ).finally(()=>{
      this.loading.pageStatisticPost = false

      })
    },
    enumIsvGet() {
      this.$http(
        "get",
        "/op/website/isv/enumIsv",
        {},
        {
          // mock: 22
        }
      ).then(res => {
        this.enumIsv = res;
       this.isvSelectObj.options=  _.unionWith(this.isvSelectObj.options,res)
        // this.isvSelectObj.options = res //包含0
        // Object.assign(this.isvSelectObj.options,res)

      });
    },
    enumAbilityGet() {
      this.$http(
        "get",
        "/op/website/isv/enumAbility",
        {},
        {
          // mock: 22
        }
      ).then(res => {
        this.enumAbility = res;
        this.abilitySelectObj.options = res
      });
    }
  } // { [key: string]: Function }
  // template: '', // render // string
  // renderError: '' // (createElement: () => VNode, error: Error) => VNode
};
</script>
<style lang="scss" scoped>
.main {
  font-size: 14px;
}
.main-item {
  background: ffffff;
}
.header-1-1 {
  border-left-width: 2px;
  padding-left: 10px;
  border-color: #333333;
}
.header-1-2 {
  font-size: 14px;
}

.main-body {
  // background-color: #fff;
}

.main-top {
  background-color: #fff;
}

.main-top-1 {
  color: #003673;
}
.pie-item-title {
  font-size: 12px;
  color: #666666;
}
.pie-item-foot-value {
  text-align: center;
  font-size: 18px;
  color: #0078ff;
}

.pie-item-foot-value--2 {
  color: #0ad4cd;
}

.pie-item-foot-key {
  margin-top: 6px;
  font-size: 10px;
  text-align: center;
  color: #999999;
}

.main-center {
  background-color: #fff;
}

.pie-item-line {
  background-color: #e8e8e8;
}

.select-key {
  font-size: 14px;
  color: #333333;
}
.select-value {
  margin-left: 20px;
}
.time-select-layout .time-select-value {
  width: 240px;
}
::v-deep .el-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ability-pie-list {
  max-width: 835px;
}
</style>